import React, { useState, useEffect } from "react";
import Meta from "../../global/Meta";
import styled from "styled-components";
import specialsBanner from "../../../img/amity-ratings.jpg";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const ReviewsWrapper = styled.div`
  .reviewsWrap {
    padding: 20px 0 0;
    border-bottom: solid 1px #eaeae9;
    background: url(https://www.queenstownservicedapartments.co.nz/img/left-quote.png)
      no-repeat;
    font-size: 18px;
    background-size: 64px;
    background-origin: content-box;
    .reviewTitle {
      padding-top: 44px;
      margin-bottom: 0;
      font-size: 24px !important;
      font-weight: 400;
    }
    .sourceWrap {
      display: block;
      font-weight: 400;
      font-style: italic;
    }
    .guestName {
      display: inline;

      span {
        display: block;
        color: orange;
        font-size: 24px;
      }
    }
    br {
      display: none;
    }
    p b:nth-child(2) {
      &:before {
        content: "(";
      }
      &:after {
        content: ")";
      }
    }
  }
  .RatingWrapper {
    h2 {
      text-align: center;
    }
    .RatingBlock {
      margin: 0 auto;
      border-bottom: solid 1px #eaeae9;
      padding: 20px 0 20px;
      text-align: center;
      .CircularProgressbar {
        width: 50%;
      }
    }
  }
`;

const Reviews = () => {
  const [resultData, setData] = useState({});

  async function fetchData() {
    const res = await fetch("/reviews-ratings.php");
    res
      .json()
      .then(res => setData(res))
      .catch(err => console.log("error"));
  }

  useEffect(() => {
    fetchData();
  }, []);
  //console.log(resultData.ratings);
  return (
    <ReviewsWrapper>
      <Meta
        title="Reviews and Ratings | Queenstown Accommodation"
        desc="Love from our guests from our different channel partners like booking.com, expedia, hotels.com, trip advisor"
        keywords="good reviews, best rating, Queenstown accommodation"
      />
      <h1>Queenstown Accommodation Customers Reviews</h1>
      <div className="header-inside">
        <img
          src={specialsBanner}
          alt="Reviews and Ratings | Queenstown Accommodation"
        />
        <div className="caption">
          <div className="caption-inner">
            <h2>Reviews &amp; Ratings</h2>
            <p>
              Live reviews from expedia, booking.com, trip advisor, hotels.com
              etc..
            </p>
          </div>
        </div>
      </div>
      <section className="white">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-8">
              <h2>Reviews</h2>
              {resultData &&
                resultData.reviews &&
                resultData.reviews.map(data => (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data
                        .replace(" Reviewed on: ", "")
                        .replace(
                          /<i aria-hidden="true" class="fa fa-star"><\/i>/gi,
                          "&#9733;"
                        )
                        .replace(
                          /<i aria-hidden="true" class="fa fa-star-o"><\/i>/gi,
                          "&#9734"
                        )
                    }}
                  />
                ))}
            </div>
            <div className="col-12 col-lg-4 RatingWrapper">
              <h2>Ratings</h2>
              {resultData &&
                resultData.ratings &&
                resultData.ratings.map(data => {
                  const splitValue = data.value.split("/");
                  const progressValue = (splitValue[0] * 100) / 5;
                  return (
                    <div class="RatingBlock">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: data.img
                        }}
                      />
                      <CircularProgressbar
                        value={progressValue}
                        text={`${progressValue}%`}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </section>
    </ReviewsWrapper>
  );
};

export default Reviews;

/*
function GetReviewsRatings() {
    const [resultData, setResultData] = useState([]);
    try {
      const response = fetch(`http://localhost:3001/app/reviews-ratings.php`)
        .then(function(response) {
          if (!response.ok) {
            throw Error(response.statusText);
          }
          return response;
        })
        .then(response => response.json)
        .then(data => {
          console.log(data);
          setResultData(data);
        });
      console.log(resultData);
      return (
        <div>
          {resultData &&
            resultData.reviews.map((index, result) => (
              <div key={index}>{result}</div>
            ))}
        </div>
      );
    } catch (err) {
      alert(err);
    }
    return [];
    //return "test";
  }
  */
