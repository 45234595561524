import React from "react";
import Meta from "../../global/Meta";

import { CustomLink } from "../../global/CustomLinks";
import BlogJson from "./BlogJson";
import LoadSection from "./LoadSection";

const Blog = ({ pageData }) => {
  const apiurl = "blog";
  const content = {
    loading: false,
    result: pageData[apiurl],
  };
  console.log("Blog");
  return (
    <div className="specials">
      {content.result && content.result.data && (
        <Meta
          metaTitle={content.result.data.metaTitle}
          metaDesc={content.result.data.metaDesc}
          metaKeywords={content.result.data.metaKeywords}
        />
      )}
      {content.result &&
        content.result.sections &&
        content.result.sections.map((item, index) => (
          <LoadSection item={item} />
        ))}
    </div>
  );
};

export default Blog;
