import React from "react";
import { Helmet } from "react-helmet";

const Meta = ({ title, desc, keywords }) => (
  <Helmet>
    <title>{title}</title>
    <meta name="keywords" content={keywords} />
    <meta name="description" content={desc} />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={desc} />
    <meta property="twitter:description" content={desc} />
    <meta property="twitter:title" content={title} />
  </Helmet>
);

export default Meta;
