const GalleryJson = [
  {
    title: "bedroom apartment",
    slugName: "",
    intro:"",
    previewImage: "https://d2057z2iq79qyw.cloudfront.net/amity/building.jpg",
    bannerImage: "https://d2057z2iq79qyw.cloudfront.net/amity/building.jpg",
    alt:"budget queenstown accommodation",
    fullDescription: "<p>&nbsp;</p> <p>&nbsp;</p>\r\n\r\n<p>&nbsp;</p>",
    metaTitle: "",
    metaDesc: "",
    metaKeywords: "skiing, snowbarding, activities, queenstown, deals"
  },
  {
    title: "bedroom apartment",
    slugName: "",
    intro:"",
    previewImage: "https://d2057z2iq79qyw.cloudfront.net/amity/building2.jpg",
    bannerImage: "https://d2057z2iq79qyw.cloudfront.net/amity/building2.jpg",
    alt:"budget queenstown accommodation",
    fullDescription: "<p>&nbsp;</p> <p>&nbsp;</p>\r\n\r\n<p>&nbsp;</p>",
    metaTitle: "",
    metaDesc: "",
    metaKeywords: "skiing, snowbarding, activities, queenstown, deals"
  },
  {
    title: "bedroom apartment",
    slugName: "",
    intro:"",
    previewImage: "https://d2057z2iq79qyw.cloudfront.net/amity/sea-view15.jpg",
    bannerImage: "https://d2057z2iq79qyw.cloudfront.net/amity/sea-view15.jpg",
    alt:"budget queenstown accommodation",
    fullDescription: "<p>&nbsp;</p> <p>&nbsp;</p>\r\n\r\n<p>&nbsp;</p>",
    metaTitle: "",
    metaDesc: "",
    metaKeywords: "skiing, snowbarding, activities, queenstown, deals"
  },
  {
    title: "bedroom apartment",
    slugName: "",
    intro:"",
    previewImage: "https://d2057z2iq79qyw.cloudfront.net/amity/slider.jpg",
    bannerImage: "https://d2057z2iq79qyw.cloudfront.net/amity/slider.jpg",
    alt:"budget queenstown accommodation",
    fullDescription: "<p>&nbsp;</p> <p>&nbsp;</p>\r\n\r\n<p>&nbsp;</p>",
    metaTitle: "",
    metaDesc: "",
    metaKeywords: "skiing, snowbarding, activities, queenstown, deals"
  },
  {
    title: "bedroom apartment",
    slugName: "",
    intro:"",
    previewImage: "https://d2057z2iq79qyw.cloudfront.net/amity/2bed-room-apt.jpg",
    bannerImage: "https://d2057z2iq79qyw.cloudfront.net/amity/2bed-room-apt.jpg",
    alt:"budget queenstown accommodation",
    fullDescription: "<p>&nbsp;</p> <p>&nbsp;</p>\r\n\r\n<p>&nbsp;</p>",
    metaTitle: "",
    metaDesc: "",
    metaKeywords: "skiing, snowbarding, activities, queenstown, deals"
  },
  {
    title: "free parking",
    slugName: "",
    intro:"",
    previewImage: "https://d2057z2iq79qyw.cloudfront.net/amity/sea-view16.jpg",
    bannerImage: "https://d2057z2iq79qyw.cloudfront.net/amity/sea-view16.jpg",
    alt: "ideal for short or long stay",
    fullDescription: "<p>&nbsp;</p> <p>&nbsp;</p>\r\n\r\n<p>&nbsp;</p>",
    metaTitle: "",
    metaDesc: "",
    metaKeywords: ""
  },
  {
    title: "single bedroom",
    slugName: "",
    intro:"",
    previewImage: "https://d2057z2iq79qyw.cloudfront.net/amity/2bedroom-apt.jpg",
    bannerImage: "https://d2057z2iq79qyw.cloudfront.net/amity/2bedroom-apt.jpg",
    alt: "single bedroom",
    fullDescription: "<p>&nbsp;</p> <p>&nbsp;</p>\r\n\r\n<p>&nbsp;</p>",
    metaTitle: "",
    metaDesc: "",
    metaKeywords: ""
  },
  {
    title: "mountain views",
    slugName: "",
    intro:"",
    previewImage: "https://d2057z2iq79qyw.cloudfront.net/amity/propertyview.jpg",
    bannerImage: "https://d2057z2iq79qyw.cloudfront.net/amity/propertyview.jpg",
    alt: "Conveniently located",
    fullDescription: "<p>&nbsp;</p> <p>&nbsp;</p>\r\n\r\n<p>&nbsp;</p>",
    metaTitle: "",
    metaDesc: "",
    metaKeywords: ""
  },
  {
    title: "spacious lounge",
    slugName: "",
    intro:"",
    previewImage: "https://d2057z2iq79qyw.cloudfront.net/amity/mountainview1.jpg",
    bannerImage: "https://d2057z2iq79qyw.cloudfront.net/amity/mountainview1.jpg",
    alt: "spacious lounge",
    fullDescription: "<p>&nbsp;</p> <p>&nbsp;</p>\r\n\r\n<p>&nbsp;</p>",
    metaTitle: "",
    metaDesc: "",
    metaKeywords: ""
  },

  {
    title: "self-contained",
    slugName: "",
    intro:"",
    previewImage: "https://d2057z2iq79qyw.cloudfront.net/amity/1bedroom-apt.jpg",
    bannerImage: "https://d2057z2iq79qyw.cloudfront.net/amity/1bedroom-apt.jpg",
    alt: "self-contained",
    fullDescription: "<p>&nbsp;</p> <p>&nbsp;</p>\r\n\r\n<p>&nbsp;</p>",
    metaTitle: "",
    metaDesc: "",
    metaKeywords: ""
  },
  {
    title: "dining area",
    slugName: "",
    intro:"",
    previewImage: "https://d2057z2iq79qyw.cloudfront.net/amity/2bed-room 5.jpg",
    bannerImage: "https://d2057z2iq79qyw.cloudfront.net/amity/2bed-room 5.jpg",
    alt: "dining",
    fullDescription: "<p>&nbsp;</p> <p>&nbsp;</p>\r\n\r\n<p>&nbsp;</p>",
    metaTitle: "",
    metaDesc: "",
    metaKeywords: ""
  },
  {
    title: "double bedroom",
    slugName: "",
    intro:"",
    previewImage: "https://d2057z2iq79qyw.cloudfront.net/amity/2bed-apt.jpg",
    bannerImage: "https://d2057z2iq79qyw.cloudfront.net/amity/2bed-apt.jpg",
    alt: "double bedroom",
    fullDescription: "<p>&nbsp;</p> <p>&nbsp;</p>\r\n\r\n<p>&nbsp;</p>",
    metaTitle: "",
    metaDesc: "",
    metaKeywords: ""
  },
  {
    title: "spacious bedroom apartment",
    slugName: "",
    intro:"",
    previewImage: "https://d2057z2iq79qyw.cloudfront.net/amity/sea-view12.jpg",
    bannerImage: "https://d2057z2iq79qyw.cloudfront.net/amity/sea-view12.jpg",
    alt: "spacious two bedroom apartment",
    fullDescription: "<p>&nbsp;</p> <p>&nbsp;</p>\r\n\r\n<p>&nbsp;</p>",
    metaTitle: "",
    metaDesc: "",
    metaKeywords: ""
  },
  {
    title: "free cafe",
    slugName: "",
    intro:"",
    previewImage: "/img/gallery/free-cafe.jpg",
    bannerImage: "/img/gallery/free-cafe.jpg",
    alt: "cafe free",
    fullDescription: "<p>&nbsp;</p> <p>&nbsp;</p>\r\n\r\n<p>&nbsp;</p>",
    metaTitle: "",
    metaDesc: "",
    metaKeywords: ""
  },
  {
    title: "kitchen amenities",
    slugName: "",
    intro:"",
    previewImage: "https://d2057z2iq79qyw.cloudfront.net/amity/2bed-room6.jpg",
    bannerImage: "https://d2057z2iq79qyw.cloudfront.net/amity/2bed-room6.jpg",
    alt:"kitchen amenities",
    fullDescription: "<p>&nbsp;</p> <p>&nbsp;</p>\r\n\r\n<p>&nbsp;</p>",
    metaTitle: "",
    metaDesc: "",
    metaKeywords: ""
  },
  {
    title: "koha spa",
    slugName: "",
    intro:"",
    previewImage: "/img/gallery/koha-spa.jpg",
    bannerImage: "/img/gallery/koha-spa.jpg",
    alt:"koha spa",
    fullDescription: "<p>&nbsp;</p> <p>&nbsp;</p>\r\n\r\n<p>&nbsp;</p>",
    metaTitle: "",
    metaDesc: "",
    metaKeywords: ""
  },
  {
    title: "lounge",
    slugName: "",
    intro:"",
    previewImage: "https://d2057z2iq79qyw.cloudfront.net/amity/mountain-view1.jpg",
    bannerImage: "https://d2057z2iq79qyw.cloudfront.net/amity/mountain-view1.jpg",
    alt:"beautiful lounge",
    fullDescription: "<p>&nbsp;</p> <p>&nbsp;</p>\r\n\r\n<p>&nbsp;</p>",
    metaTitle: "",
    metaDesc: "",
    metaKeywords: ""
  },
  {
    title: "outdoor entertainment",
    slugName: "",
    intro:"",
    previewImage: "https://d2057z2iq79qyw.cloudfront.net/amity/sli.jpg",
    bannerImage: "https://d2057z2iq79qyw.cloudfront.net/amity/sli.jpg",
    alt:"outdoor entertainment",
    fullDescription: "<p>&nbsp;</p> <p>&nbsp;</p>\r\n\r\n<p>&nbsp;</p>",
    metaTitle: "",
    metaDesc: "",
    metaKeywords: ""
  },
  {
    title: "single bedroom",
    slugName: "",
    intro:"",
    previewImage: "https://d2057z2iq79qyw.cloudfront.net/amity/1bed-room.jpg",
    bannerImage: "https://d2057z2iq79qyw.cloudfront.net/amity/1bed-room.jpg",
    alt:"single bed",
    fullDescription: "<p>&nbsp;</p> <p>&nbsp;</p>\r\n\r\n<p>&nbsp;</p>",
    metaTitle: "",
    metaDesc: "",
    metaKeywords: ""
  },
  {
    title: "single bedroom",
    slugName: "",
    intro:"",
    previewImage: "https://d2057z2iq79qyw.cloudfront.net/amity/2bed-room.jpg",
    bannerImage: "https://d2057z2iq79qyw.cloudfront.net/amity/2bed-room.jpg",
    alt:"single bed",
    fullDescription: "<p>&nbsp;</p> <p>&nbsp;</p>\r\n\r\n<p>&nbsp;</p>",
    metaTitle: "",
    metaDesc: "",
    metaKeywords: ""
  },
  
  
  
];

export default GalleryJson;
