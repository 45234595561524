import React from "react";
import Meta from "../../global/Meta";
import { NormalLink } from "../../global/CustomLinks";
import FooterSeo from "../../global/FooterSeo";
import { bookingLink } from "../../global/constants";

const Links = () => (
  <div>
    <Meta
      title="Links | Amity Queenstown Accommodation"
      desc="Directory Links | Amity Queenstown Accommodation"
      keywords="covid-19 queenstown accommodation, self isolation accommodation in queenstown, queenstown accommodation"
    />
    <h1>Links </h1>
    <div class="header-inside">
      <img
        src="/img/covid-19-self-isolation-accommodation-nz.jpg"
        alt="Covid 19 self isolation queenstown accommodation"
      />
      <div class="caption">
        <div class="caption-inner">
          <h2>Links</h2>
        </div>
      </div>
    </div>
    <section class="grey">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-12">
            <p>Get 15% off. Book Now</p>
            <NormalLink
              type="btn book-now"
              rel="nofollow"
              href={bookingLink}
              text="Book Now"
            />
            <p>
              <a
                rel="nofollow"
                href="https://www.newzealand.com/nz/plan/business/amity-serviced-apartments-queenstown/"
                target="_blank"
              >
                100% Pure New Zealand
              </a>
            </p>
            <p>
              <a
                rel="nofollow"
                href="https://www.tripadvisor.com/Hotel_Review-g255122-d1016335-Reviews-Amity_Serviced_Apartments-Queenstown_Otago_Region_South_Island.html"
                target="_blank"
              >
                Tripadvisor
              </a>
            </p>
            <p>
              <a
                rel="nofollow"
                href="https://en.wikivoyage.org/wiki/Queenstown_(New_Zealand)"
                target="_Blank"
              >
                Wikivoyage
              </a>
            </p>
            <p>
              <a
                rel="nofollow"
                href="http://www.mkiwi.com/New_Zealand/Directory/Otago/Localities/Queenstown/Travel_and_Tourism/Accommodation/Hotels_and_Motels/"
              >
                mKiwi New Zealand Search Engine
              </a>
            </p>
            <p>
              <a href="http://www.mkiwi.com/">
                mKiwi New Zealand Search Engine
              </a>
            </p>
            <p>
              <a
                rel="nofollow"
                href="https://www.queenstown.com/accommodation/amity-queenstown-serviced-apartments"
                target="_blank"
              >
                Queenstown Accommodation
              </a>
            </p>
            <p>
              <a
                rel="nofollow"
                href="https://www.booking.com/hotel/nz/amity-lodge-motel.en-gb.html"
                target="_blank"
              >
                Booking.com Hotel
              </a>
            </p>
            <p>
              <a
                href="nofollow"
                href-="https://nz.hotels.com/ho358838"
                target="_blank"
              >
                NZ Hotels.com
              </a>
            </p>

            <p>
              <a
                rel="nofollow"
                href="https://www.tourism.net.nz/region/queenstown/accommodation/apartments/other-listings"
                target="_blank"
              >
                Tourism Accommodation Apartments
              </a>
            </p>
            <p>
              <a
                rel="nofollow"
                href="http://www.nzsearch.co.nz/search.aspx?q=queenstown&page=5"
                target="_blank"
              >
                NZ Search Amity Serviced Apartments
              </a>
            </p>
            <p>
              <a
                rel="nofollow"
                href="http://www.nzsearch.co.nz/search.aspx?q=queenstown&page=2"
                target="_blank"
              >
                NZ Search Amity Lodge
              </a>
            </p>
            <p>
              <a
                rel="nofollow"
                href="https://www.nzsbdirectory.co.nz/NZSB_Otago_Lodges.html"
                target="_blank"
              >
                {" "}
                NZSB Directory
              </a>
            </p>
            <p>
              <a
                rel="nofollow"
                href="https://www.nzdirectory.co.nz/profile/listed.php?www.queenstownservicedapartments.co.nz"
                target="_blank"
              >
                Accommodation in Queenstown - NZ directory
              </a>
            </p>
            <p>
              <a
                rel="nofollow"
                href="https://www.nzdirectory.co.nz/tourism-travel-2.html"
                target="_blank"
              >
                NZ Direcoty Tourism Travel
              </a>
            </p>
            <p>
              <a
                rel="nofollow"
                href="http://www.nzpages.co.nz/travel_and_tourism/accommodation/bed_and_breakfast/southland/4/a/"
                target="_blank"
              >
                NZpages
              </a>
            </p>
            <p>
              {" "}
              <a
                rel="nofollow"
                href="https://www.nzdirectory.co.nz/accommodation-2.html"
                target="_blank"
              >
                Accommodation
              </a>
            </p>
            <p>
              {" "}
              <a
                rel="nofollow"
                href="https://www.fyple.co.nz/company/serviced-apartments-accommodation-in-queenstown-1qi8ehf/"
                target="_blank"
              >
                Fyple
              </a>
            </p>
            <p>
              {" "}
              <a
                rel="nofollow"
                href="https://nearfindernz.com/business/ota/queenstown/accommodation-booking/amity-serviced-apartments_195726+7.html"
                target="_blank"
              >
                NearFinder Business Directory
              </a>
            </p>
            <p>
              <a
                rel="nofollow"
                href="http://www.nzpages.co.nz/search.html?q=amity+queenstown"
                target="_blank"
              >
                NZPAGES New Zealand
              </a>
            </p>
            <p>
              <a
                rel="nofollow"
                href="http://www.myfishingguide.co.nz/listing/241810/amity-serviced-apartments-accommodation-in-queenstown"
                target="_blank"
              >
                {" "}
                Myfishingguide listing
              </a>
            </p>
            <p>
              <a
                rel="nofollow"
                href="https://www.thedirectorylistings.org/Travel/Hotels_and_Accomodation_/"
                target="_blank"
              >
                The Directory Listings
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
    <FooterSeo content="Self-isolation accommodation, Lockdown stay, short &amp; long term stay, Quarantine‎, Queenstown, Coronavirus NZ " />
  </div>
);

export default Links;
