import React from "react";
import Meta from "../../global/Meta";
import tripadvisor from "../../../img/tripadvisor-excellence-certificate.jpg";
import tripadvisor_reviews from "../../../img/tripadvisor-motel-reviews.jpg";
import tripadvisor_recommended from "../../../img/best-recommended-on-tripadvisor.jpg";
import hotelAward from "../../../img/hotel-award.jpg";
import tripadvisorAchievement from "../../../img/trip-advisor-achievement.jpg";
import booking_com_award from "../../../img/booking_com_social_media.png";
import "./About.scss";

const About = () => (
  <div>
    <Meta
      title="Amity Queenstown Accommodation New Zealand. Ph 034427288"
      desc="Amity looks to bring adventure, culture and unforgettable memories right at your doorstep.  "
      keywords="Accommodation in Queenstown, NZ, motel, apartment, accommodation deals"
    />
    <h1>Book Direct with Amity to get 15% on Queenstown Accommodation</h1>
    <div class="header-inside">
      <img src="https://d2057z2iq79qyw.cloudfront.net/amity/outside-amity.jpg" alt="About amity queenstown accommodation" />
      <div class="caption">
        <div class="caption-inner">
          <h2>About us</h2>
          <p>Make your stay more pleasant with Amity! </p>
        </div>
      </div>
    </div>
    <section class="grey">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-12">
            <h3>Welcome Guest!</h3>

            <p class="subtitle">
              AMITY....means friendly, cordial &amp; peaceful.{" "}
            </p>
            <p>
              Exceptional yet friendly service from every hello. Explore
              Queenstown through the eyes of the locals, with us by your side
              catering for your every need. With apartments surrounded by
              breathtaking views of the mountains, Amity looks to bring
              adventure, culture and unforgettable memories right at your
              doorstep. Visit our activities page for information regarding
              upcoming events in Queenstown, and nearby attractions to suit all
              interests.
            </p>
            <p>
              The mountains are cold and our service is warm. We’re true to our
              name and we pride ourselves on our cozy, well-kept rooms, with
              free unlimited Wifi and Sky TV on offer. Situated only a short
              walk from the town centre, Amity hosts well-lit apartments amongst
              mountain views that are sure to cater to your every need.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="white" id="awards">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-12">
            <h3> Awards and Accolades - Certificates of Excellence</h3>
            <p class="subtitle">Achievements earned by the Amity Lodge</p>
            <p>
              See why we pride ourselves on our hard work, service and
              exceptional team.
            </p>
          </div>
          <div class="col-12 col-lg-12">
            <div class="row">
              <div class="col-12 col-md-4">
                <img
                  class="img-fluid"
                  src={booking_com_award}
                  alt="certificate of excellence"
                />
              </div>
              <div class="col-12 col-md-4">
                <img
                  class="img-fluid"
                  src={hotelAward}
                  alt="certificate of excellence"
                />
              </div>
              <div class="col-12 col-md-4">
                <img
                  class="img-fluid"
                  src={tripadvisorAchievement}
                  alt="trip advisor reviews"
                />
              </div>
              <div class="col-12 col-md-4">
                <img
                  class="img-fluid"
                  src={tripadvisor}
                  alt="certificate of excellence"
                />
              </div>

              <div class="col-12 col-md-4">
                <img
                  class="img-fluid"
                  src={tripadvisor_reviews}
                  alt="trip advisor reviews"
                />
              </div>
              <div class="col-12 col-md-4">
                <img
                  class="img-fluid"
                  src={tripadvisor_recommended}
                  alt="recommended on trip advisor"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
);

export default About;
