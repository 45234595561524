import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { NormalLink } from "../../global/CustomLinks";
import spa from "../../../img/koha-spa.jpg";
import { bookingLink } from "../../global/constants";

class HomePageSlider extends React.Component {
    render() {
        var settings = {
            dots: true,
            infinite: true,
            fade: true,
            autoplay: true,
            speed: 4000,
            slidesToShow: 1,
            slidesToScroll: 1,
        };
        return (
            <Slider {...settings}>
                <div>
                    <img
                        src="https://d2057z2iq79qyw.cloudfront.net/amity/frontlook.jpg"
                        alt="vacation home"
                        className="slider-img"
                    />
                    <div className="caption">
                        <div className="caption-inner">
                            <h2>Stylish &amp; Comfortable</h2>
                            <p>
                                AMITY make sure your stay is warm and our deals are
                                HOT!
                            </p>

                            <NormalLink
                                type="btn book-now"
                                rel="nofollow"
                                href={bookingLink}
                                text="Book Now"
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <img src="https://d2057z2iq79qyw.cloudfront.net/amity/book-accommodation-queenstown.jpg" alt="cozy stay" className="slider-img" />
                    
                    <div className="caption">
                        <div className="caption-inner">
                            <h2>Life’s better by the mountainside</h2>
                            <p>
                                An ideal apartment for a pocket friendly, cordial
                                and relaxed holiday
                            </p>

                            <NormalLink
                                type="btn book-now"
                                rel="nofollow"
                                href={bookingLink}
                                text="Book Now"
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <img
                        src="https://d2057z2iq79qyw.cloudfront.net/amity/outside-amity.jpg"
                        alt="best motel, apartment to stay"
                        className="slider-img"
                    />
                    <div className="caption">
                        <div className="caption-inner">
                            <h2>Where warm memories last</h2>
                            <p>Pack your bags for a snowy trip to Queenstown</p>

                            <NormalLink
                                type="btn book-now"
                                rel="nofollow"
                                href={bookingLink}
                                text="Book Now"
                            />
                        </div>
                    </div>
                </div>

            </Slider>
        )
    }
}

export default HomePageSlider;
