import React from "react";
import { NavLink } from "react-router-dom";
import Nav from "../Nav/Nav";

import "./Header.scss";
import logo from "../../../img/amitylogo.png";

const Header = () => (
  <div>
    <div className="header container">
      <div className="row  align-items-center">
        <div className="col-12  col-md-6 col-lg-4 center-xs">
          <NavLink exact className="logo" to="/">
            <img
              className="logoImg"
              src={logo}
              alt="Serviced apartments accommodation in Queenstown"
            />
          </NavLink>
        </div>
        <div className="col-12 col-md-6 col-lg-8">
          {/*
          <div className="row  justify-content-end">
            <div className="col-12 text-right">
              <div className="call-action">
                <span>
                  <i className="fas fa-envelope"></i>
                  <a href="mailto:info@amityqueenstown.co.nz">
                    info@amityqueenstown.co.nz
                  </a>
                </span>
                <span>
                  <i className="fas fa-phone"></i>
                  <a href="tel://006434427288">+64 3 442 7288</a>
                </span>
                <span>
                  <a href="tel://0800556000"> 0800 556 000</a>
                </span>
              </div>
            </div>
          </div>
*/ }
          <div className="row justify-content-end">
            <div className="col-12">
              <Nav />
            </div>
          </div>
        </div>
      </div>
    </div>
    {
      /*
      <div className="header container">
      <div className="row  align-items-center">
        <div className="col-12 col-md-12 center-xs">
          <Nav />
        </div>
      </div>
    </div>
      */
    }

  </div>
);

export default Header;
