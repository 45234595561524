const ActivitiesJson = [
  {
    title: "The Remarkables Ski Area",
    slugName: "skiing-and-snowboarding-in-queenstown",
    intro:
      "Explore Queenstown Arrowtown,& the Gibbston Valley by bike & sample local food, wine & craft beer along the way with Fork & Pedal Tours.",
    previewImage: "/img/activities/the-remarkables-ski-area.jpg",
    bannerImage: "/img/activities/the-remarkables-ski-area.jpg",
    fullDescription: "<p>&nbsp;</p> <p>&nbsp;</p>\r\n\r\n<p>&nbsp;</p>",
    metaTitle: "",
    metaDesc: "",
    metaKeywords: "skiing, snowbarding, activities, queenstown, deals"
  },
  {
    title: "Milford Sound Cruises & Tours",
    slugName: "milford-sound-cruise-tour",
    intro:
      "For an experience like no other, board a cruise and get out on the water.From day cruises to cruises held overnight, there are plenty of chances to experience the Milford Sound in all its different forms. It truly must be seen to be believed.",
    previewImage: "/img/activities/milford-sound-nz.jpg",
    bannerImage: "/img/activities/milford-sound-nz.jpg",
    fullDescription: "<p>&nbsp;</p> <p>&nbsp;</p>\r\n\r\n<p>&nbsp;</p>",
    metaTitle: "",
    metaDesc: "",
    metaKeywords: ""
  },
  {
    title: "Skyline Gondola & Luge",
    slugName: "skyline-gondola-and-luge",
    intro:
      "Whether you chase thrills or serene views, This attraction offers something for everyone. Take control in the driver’s seat in the Queenstown Luge, or take the backseat in the renowned Queenstown Cable Car for endless views. Either way, you won’t be disappointed. ",
    previewImage: "/img/activities/skyline-gondola-and-luge.jpg",
    bannerImage: "i/img/activities/skyline-gondola-and-luge.jpg",
    fullDescription: "<p>&nbsp;</p> <p>&nbsp;</p>\r\n\r\n<p>&nbsp;</p>",
    metaTitle: "",
    metaDesc: "",
    metaKeywords: ""
  },
  {
    title: "Coronet Peak Ski Area",
    slugName: "coronet-peak-ski-area",
    intro:
      "Ditch the track and get within the mountain snow for an adrenaline rush like no other on one of the most exciting ski resorts New Zealand has to offer. This mountain is suitable for pros and first-timers alike. Coronet Peak also holds a cafe, a guided walking tour and bike trails for those wanting to soak in the views at their own pace.    ",
    previewImage: "/img/activities/coronet-peak-ski-area.jpg",
    bannerImage: "/img/activities/coronet-peak-ski-area.jpg",
    fullDescription: "<p>&nbsp;</p> <p>&nbsp;</p>\r\n\r\n<p>&nbsp;</p>",
    metaTitle: "",
    metaDesc: "",
    metaKeywords: ""
  },
  {
    title: "Ben Lomond",
    slugName: "ben-lomond-mountain-hiking-track",
    intro:
      "If you’re after a challenge, look no further. This full day walking track has a 1438m elevation gain. The track only gets steeper and tougher, and those who make it to the top are rewarded with a 360 degree panoramic view of Queenstown everyone below will envy them for.",
    previewImage: "/img/activities/hiking-the-ben-lomond-track.jpg",
    bannerImage: "/img/activities/hiking-the-ben-lomond-track.jpg",
    fullDescription: "<p>&nbsp;</p> <p>&nbsp;</p>\r\n\r\n<p>&nbsp;</p>",
    metaTitle: "",
    metaDesc: "",
    metaKeywords: ""
  },
  {
    title: "Lake Wanaka",
    slugName: "lake-wanaka-queenstown",
    intro:
      "Enjoy the natural beauty in Lake Wanaka. Perfect for jet boating, sailing, kayaking, or even for an evening stroll and amazing photography. Cafes, restaurants and galleries are all at your footsteps.",
    previewImage: "/img/activities/lake-wanaka-queenstown.jpg",
    bannerImage: "/img/activities/lake-wanaka-queenstown.jpg",
    fullDescription: "<p>&nbsp;</p> <p>&nbsp;</p>\r\n\r\n<p>&nbsp;</p>",
    metaTitle: "",
    metaDesc: "",
    metaKeywords: ""
  },
  {
    title: "Queenstown Bungy, Swing & Zip",
    slugName: "queenstown-bungy-swing-and-zip",
    intro:
      "Whether you want to jump, fly or swing, Queenstown is home to the most thrilling adventures. Zip through a forest at high speeds or swing on the world’s highest swing amongst the skies, both will undoubtedly send a rush that will take your breath away.",
    previewImage: "/img/activities/queenstown-bungy-swing-and-zip.jpg",
    bannerImage: "/img/activities/queenstown-bungy-swing-and-zip.jpg",
    fullDescription: "<p>&nbsp;</p> <p>&nbsp;</p>\r\n\r\n<p>&nbsp;</p>",
    metaTitle: "",
    metaDesc: "",
    metaKeywords: ""
  },
  {
    title: "Queenstown's Wine Tasting, Food & Vineyard Tours",
    slugName: "queenstown-wine-tasting-vineyard-tour",
    intro:
      "After the best Pinot Noir you’ve ever tasted? Then look no further. Tours can be done in a car, on a bike or even on a helicopter! With numerous varieties to choose from, the wineries in Queenstown will not disappoint.",
    previewImage: "/img/activities/otago-wine-tasting-vineyard-tour.jpg",
    bannerImage: "/img/activities/otago-wine-tasting-vineyard-tour.jpg",
    fullDescription: "<p>&nbsp;</p> <p>&nbsp;</p>\r\n\r\n<p>&nbsp;</p>",
    metaTitle: "",
    metaDesc: "",
    metaKeywords: ""
  },
  {
    title: "Skydive Queenstown",
    slugName: "queenstown-school-holidays",
    intro:
      "Accomplish the most challenging challenge and overcome your fear, solo or tandem. It won’t be easy but it will be worth it. 15,000ft up in the air at 200km/hr. Would you jump?",
    previewImage: "/img/activities/queenstown-skydiving.jpg",
    bannerImage: "/img/activities/queenstown-skydiving.jpg",
    fullDescription: "<p>&nbsp;</p> <p>&nbsp;</p>\r\n\r\n<p>&nbsp;</p>",
    metaTitle: "",
    metaDesc: "",
    metaKeywords: ""
  },
  {
    title: "Flyboard Queenstown",
    slugName: "flyboard-adrenalin-water-activity",
    intro:
      "Ever wanted to fly? Flyboarding takes water activities to the next level. More fun, more height, more adrenaline. With rocket boots strapped to your feet, flying on water is a piece of cake.",
    previewImage: "/img/activities/flyboard-adrenalin-activity.jpg",
    bannerImage: "/img/activities/flyboard-adrenalin-activity.jpg",
    fullDescription: "<p>&nbsp;</p> <p>&nbsp;</p>\r\n\r\n<p>&nbsp;</p>",
    metaTitle: "",
    metaDesc: "",
    metaKeywords: ""
  },
  {
    title: "A Thrilling Semi-submersible Shark Ride",
    slugName: "queenstown-school-holidays",
    intro:
      "Ride with the (hydro)sharks in this innovative activity. Fly straight up into the air, and swim deep underwater just like real sharks. With an experienced and trained pilot by your side, you can reach speeds of 80kph on water and 40kph underwater.",
    previewImage: "/img/activities/hydro-attack-shark-ride.jpg",
    bannerImage: "/img/activities/hydro-attack-shark-ride.jpg",
    fullDescription: "<p>&nbsp;</p> <p>&nbsp;</p>\r\n\r\n<p>&nbsp;</p>",
    metaTitle: "",
    metaDesc: "",
    metaKeywords: ""
  }
];

export default ActivitiesJson;
