import React from "react";
import Meta from "../../global/Meta";
import aboutBanner from "../../../img/amity-lodge-motel-queenstown.jpg";

import "../About/About.scss";

const Privacy = () => (
  <div>
    <Meta
      title="Privacy Policy | Amity Queenstown Accommodation New Zealand"
      desc="Privacy policy for amity serviced apartments queenstown"
      keywords="Accommodation in Queenstown, NZ, motel, apartment, accommodation deals"
    />
    <h1>Privacy Policy | Amity Queenstown Accommodation</h1>
    <div class="header-inside">
      <img src={aboutBanner} />
      <div class="caption">
        <div class="caption-inner">
          <h2>Privacy Policy</h2>
          
        </div>
      </div>
    </div>
    <section class="grey">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-12">
            

            <h3>Privacy Policy</h3>
             <p>This privacy policy sets out how we use and protects any information that you give us when you use this website.<br/>
             We are committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this website, then you can be assured that it will only be used in accordance with this privacy statement.<br/>
             We may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are happy with any changes.<br/><br/>
             </p>
             <h4>What we collect</h4>
             <p>We may collect the following information:<br/> 
             name and job title<br/>
             contact information including email address<br/>
             demographic information such as postcode, preferences and interests<br/><br/></p>
             <h4>What we do with the information we gather</h4>
             We require this information to understand your needs and provide you with a better service, and for the following reasons:<br/>
             Internal record keeping.<br/>We may use the information to improve our products and services.<br/>
             We may periodically send promotional email about new products, special offers or other information which we think you may find interesting using the email address which you have provided, 
             
             <h2>to do this you need to opt in for this service.</h2>
             We may contact you by email, phone, or mail.<br/>
             We may use the information to customise the website according to your interests.<br/><br/>
             <h4>Security</h4>
             We are committed to ensuring that your information is secure.To prevent unauthorised access or disclosure we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect online.<br/><br/>
             <h4>How we use cookies</h4>
             A cookie is an element of data that a website can send to your browser, which may then store it on your system. We use cookies in some of our pages to store your preferences and record session information. The information that we collect is then used to ensure a more personalized service level for our users. Please be assured though that your credit card number will not be saved for security reasons. You must type the credit card number each time you make a purchase.<br/>
             You can adjust settings on your browser so that you will be notified when you receive a cookie. Please refer to your browser documentation to check if cookies have been enabled on your computer or to request not to receive cookies. As cookies allow you to take advantage of some of the Websites essential features, we recommend that you accept cookies. For instance, if you block or otherwise reject our cookies, you will not be able to book flights or use any products or services on the Website that require you to log-in.<br/>
             This information is treated confidentially and will not be shared with anyone outside of the Company unless otherwise stated in this Privacy Policy. We will only use this information to make informed decisions regarding the purchase of online advertising.<br/><br/>
             <h4>Links to other websites</h4>
            Our website may contain links to enable you to visit other websites of interest easily. However, once you have used these links to leave our site, you should note that we do not have any control over that other website. Therefore, we cannot be responsible for the protection and privacy of any information which you provide whilst visiting such sites and such sites are not governed by this privacy statement. You should exercise caution and look at the privacy statement applicable to the website in question.
             <h4>Controlling your personal information</h4>
             You may choose to restrict the collection or use of your personal information in the following ways:<br/>We will not sell, distribute or lease your personal information to third parties unless we have your permission or are required by law to do so.<br/>You may request details of personal information which we hold about you under the Data Protection Act 1998<br/>If you believe that any information we are holding on you is incorrect or incomplete, please write to or email us as soon as possible, at the above address. We will promptly correct any information found to be incorrect.
           
          </div>
        </div>
      </div>
    </section>
    
  </div>
);

export default Privacy;
