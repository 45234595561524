import React from "react";
import Meta from "../../global/Meta";
import { CustomLink } from "../../global/CustomLinks";
import "./Home.scss";
import HomePageSlider from "./HomePageSlider";
import WhyBookWithUs from "./WhyBookWithUs";
import Deals from "./Deals";
import FooterSeo from "../../global/FooterSeo";

const Home = () => (
  <div>
    <Meta
      title="Queenstown Accommodation | Hotels in Queenstown"
      desc="Explore top-rated Queenstown accommodations with Amity Queenstown. Enjoy luxury amenities, stunning views, and unbeatable prices. Book now!"
      keywords="Queenstown hotels, Hotels in Queenstown, Queenstown accommodation, Accommodation in Queenstown"
    />
    <h1>Book Low Price Ideal Queenstown Accommodation With Amity</h1>
    <div id="homepageSlider">
      <HomePageSlider />
    </div>
    <section className="homepage-about-us">
      <div className="container">
        <div className="row">
          <div className="col-md-9 col-12">
            <h2>Welcome to Amity Queenstown Accommodation.</h2>
            <p>
              Exceptional yet friendly service from every hello. Explore
              Queenstown through the eyes of the locals, with us by your side
              catering for your every need. In a location among 1000 things
              waiting to be discovered, you will escape from the ordinary and
              immerse yourself in a unique blend of curiosity and
              sophistication. This charming apartment is tastefully decorated in
              a cosy and homely style, while expressing the modern spirit of
              Queenstown. With apartments surrounded by breathtaking views of
              the mountains, Amity looks to bring adventure, culture and
              unforgettable memories right at your doorstep.
            </p>

            <CustomLink type="btn read-more" href="/about/" text="Read more" />
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-4">
            <WhyBookWithUs />
          </div>
          <div className="col-12 col-md-6 col-lg-8">
            <Deals />
          </div>
        </div>
      </div>
    </section>
    <section className="homepage-section">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6">
            <a
              className="card-block-big first"
              href="/self-contained-serviced-apartments/"
            >
              <div className="overlay"></div>
              <CustomLink
                href="/self-contained-serviced-apartments/"
                type="btn learn-more"
                text="Accommodation"
              />
              <div className="content">
                <h4>Accommodation</h4>
                <p>
                  If you are visitng the South Island, New Zealand, You've come
                  to the right place to find your{" "}
                  <b>
                    <i>accommodation</i>
                  </b>{" "}
                  in the heart of downtown{" "}
                  <b>
                    <i>Queenstown</i>
                  </b>{" "}
                  for groups and couples to large families, to suit every taste
                  and budget. Feels like your own home. For sleep as good as
                  home. Choose any of our comfortable and well looked after
                  apartments, each one as unique as your stay.
                </p>
                <p>
                  <i>Learn more</i>
                </p>
              </div>
            </a>
          </div>
          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-12">
                <a className="card-block-big second" href="/activities/">
                  <div className="overlay"></div>
                  <CustomLink
                    href="/activities/"
                    type="btn learn-more"
                    text="Things to do"
                  />
                  <div className="content">
                    <h4>Things to do</h4>
                    <p>
                      For engaging experiences and pleasures to savour. Click
                      here to discover adventurous and cultural activities that
                      will make your stay here like no other...
                    </p>
                    <p>
                      <i>Learn more</i>
                    </p>
                  </div>
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12">
                <a className="card-block-big fourth" href="/gallery/">
                  <div className="overlay"></div>
                  <CustomLink
                    href="/gallery/"
                    type="btn learn-more"
                    text="Gallery"
                  />
                  <div className="content">
                    <h4>Gallery</h4>
                    <p>View the images and catch a glimpse before you stay...</p>
                    <p>
                      <i>Learn more</i>
                    </p>
                  </div>
                </a>
              </div>
              
            </div>
          </div>
        </div>
        
      </div>
    </section>
    <FooterSeo content="Better seo footer" />
  </div>
);

export default Home;
