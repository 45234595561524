import React from "react";
import Banner from "./Banner";
import ContentSection from "./ContentSection";
import MultipageSection from "./MultipageSection";

const LoadSection = ({ pageName, item }) => {
  console.log("LoadSection", item);
  return (
    <>
      {item[0].sectionLayoutId === "110" && item[0].sectionTypeId === "3" && (
        <Banner item={item[0]} />
      )}
      {item[0].sectionLayoutId === "113" &&
        item[0].sectionTypeId === "7" &&
        item.map((item1, index1) => (
          <MultipageSection item={item1} key={index1} index={index1} />
        ))}
      {item[0].sectionLayoutId === "111" && item[0].sectionTypeId === "2" && (
        <ContentSection item={item[0]} />
      )}
    </>
  );
};

export default LoadSection;
