import React from "react";
import Meta from "../../global/Meta";
import AccommodationSlider from "./AccommodationSlider";
import "./Accommodation.scss";

import { NormalLink, CustomLink1 } from "../../global/CustomLinks";
import { bookingLink } from "../../global/constants";

import {
  faBed,
  faUser,
  faWifi,
  faBath,
  faTv,
  faUtensils,
  faWheelchair,
  faCouch,
  faTshirt,
  faParking,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Accommodation = () => (
    <div className="accommodation">
        <Meta
            title="Queenstown Serviced self-contained holiday Apartments"
            desc="The perfect vacation apartment in Queenstown, New Zealand? Amity serviced apartments offers a range of accommodation facilities, lovely spacious, north facing one and two bedroom apartments with Kitchenette, dining area, living room and spectacular views of the Remarkables and Lake Wakatipu. Book Now Online!"
            keywords="apartment, queenstown, accommodation, self-contained, kitchen, holiday apartment, holiday, vacation, new zealand, bookings, bed, rooms, bedroom apartment"
        />
        <h1>
            The perfect vacation apartment in Queenstown NZ - Amity Serviced
            Apartments
        </h1>
        <div className="header-inside">
            <img
                src="https://d2057z2iq79qyw.cloudfront.net/amity/2b.jpg"
                alt="self contained serviced queenstown apartment"
            />
            <div className="caption">
                <div className="caption-inner">
                    <h2>Vacation Apartments in Queenstown</h2>
                </div>
            </div>
        </div>
        <section className="white">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-12">
                        <p>
                            {" "}
                            If it’s a serviced self apartment that you’re
                            looking for to make your holiday perfect,{" "}
                            <a href="/">Amity Queenstown</a> is the perfect
                            choice for a home away from home. Friends, couples,
                            families and longer staying guests can all enjoy the
                            marvelous views of The Remarkables and the
                            surrounding peaks, as well as stunning views of Ben
                            Lomond all in the comfort of their apartment,
                            allowing for comfort and style where the focus stays
                            on the holiday.{" "}
                        </p>

                        <p>
                            Our{" "}
                            <b>
                                <i>self contained queenstown apartments</i>
                            </b>{" "}
                            have spectacular lake and mountain views, well-kept
                            living and dining area, fully equipped kitchen,
                            generous sized bedrooms, flat screen TVs, free
                            unlimited Wifi and Sky TV, DVD players, and free
                            off-street parking. Guests can also enjoy a private
                            backyard when staying in one of our two bedroom
                            apartments to take advantage of the quiet and
                            secluded surroundings to rest and unwind with their
                            loved ones. Rest assured, comfort, warmth, privacy
                            and spacious living make up your wonderful stay with
                            us.
                        </p>

                        <p>
                            Other services include{" "}
                            <a href="/">bike/car rental</a>, self service
                            laundry facilities, daily housekeeping, luggage
                            storage, and much more.
                        </p>

                        <h3>
                            Choose the accommodation most suitable for you
                            below:{" "}
                        </h3>
                    </div>
                </div>
            </div>
        </section>
        <section className="grey">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <AccommodationSlider content='["https://d2057z2iq79qyw.cloudfront.net/amity/1bed-apt4.jpg", "https://d2057z2iq79qyw.cloudfront.net/amity/1bed-room.jpg"]' />
                    </div>
                    <div className="col-12 col-lg-6">
                        <h3>One Bedroom Apartments</h3>

                        <p className="subtitle">
                            Maximum occupancy is 3 guests, approximate size
                            50sqm+ .
                        </p>
                        <p>
                            With one Queen bed and one Single bed available in
                            each room, this choice is ideal for a couple or
                            small families. Our warm and comfy beds are a treat,
                            with no room for disappointment with our spacious
                            living space and modern rooms.
                        </p>
                        <p className="room-type-large-icon">
                            <FontAwesomeIcon icon={faBed} /> Queen Bed and
                            Single Bed
                        </p>
                        <p className="room-type-large-icon">
                            <FontAwesomeIcon icon={faUser} /> 2 - 3 Adults
                        </p>
                        <hr />
                        <ul className="facilities-icon">
                            <li>
                                <FontAwesomeIcon icon={faCouch} />
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faUtensils} />
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faWifi} />
                            </li>
                        
                            <li>
                                <FontAwesomeIcon icon={faBath} />
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faTv} />
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faWheelchair} />
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faTshirt} />
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faParking} />
                            </li>
                        </ul>

                        <NormalLink
                            type="btn book-now"
                            rel="nofollow"
                            href={bookingLink}
                            text="Book Now"
                        />
                    </div>
                </div>
            </div>
        </section>
        <section className="white">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <h3>
                            One Bedroom Apartments
                            <span>with mountain view</span>
                        </h3>
                        <p className="subtitle">
                            Maximum occupancy is 3 guests, approximate size
                            50sqm+ .
                        </p>
                        <p>
                            Wake up to spectacular views of The Remarkables and
                            stunning views of Ben Lomond all in the comfort of
                            your apartment, allowing you to experience
                            tranquility and peace like never before. With one
                            Queen bed and one Single bed, enjoy your home away
                            from home with a complete kitchenette, a spacious
                            living space and dining area, unlimited free Wifi
                            and complimentary water bottles in every room.
                        </p>
                        <p className="room-type-large-icon">
                            <FontAwesomeIcon icon={faBed} /> Queen Bed and
                            Single Bed
                        </p>
                        <p className="room-type-large-icon">
                            <FontAwesomeIcon icon={faUser} /> 2 - 3 Adults
                        </p>
                        <hr />
                        <ul className="facilities-icon">
                            <li>
                                <FontAwesomeIcon icon={faCouch} />
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faUtensils} />
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faWifi} />
                            </li>
                    
                            <li>
                                <FontAwesomeIcon icon={faBath} />
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faTv} />
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faWheelchair} />
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faTshirt} />
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faParking} />
                            </li>
                        </ul>

                        <NormalLink
                            type="btn book-now"
                            rel="nofollow"
                            href={bookingLink}
                            text="Book Now"
                        />
                    </div>
                    <div className="col-12 col-lg-6 order">
                        <AccommodationSlider content='["https://d2057z2iq79qyw.cloudfront.net/amity/1bedroom-apt1.jpg", "https://d2057z2iq79qyw.cloudfront.net/amity/1bed-apt5.jpg"]' />
                    </div>
                </div>
            </div>
        </section>
        <section className="grey">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <AccommodationSlider content='["https://d2057z2iq79qyw.cloudfront.net/amity/1bed-apt5.jpg", "https://d2057z2iq79qyw.cloudfront.net/amity/2bed-hill.jpg", "https://d2057z2iq79qyw.cloudfront.net/amity/kitchen.jpg"]' />
                    </div>
                    <div className="col-12 col-lg-6">
                        <h3>Two Bedroom Apartments</h3>

                        <p className="subtitle">
                            Maximum occupancy is 4 guests, approximate size
                            50sqm+.
                        </p>
                        <p>
                            Incredible views of Ben Lomond compliment this two
                            bedroom apartment. The apartments are situated on
                            the ground floor, allowing for easy access. Enjoy a
                            private backyard exclusive to you and your
                            friends/family, where you can enjoy time together
                            with a warm cup of coffee. Enjoy a complete
                            Kitchenette, and a dining and living area.
                        </p>
                        <p className="room-type-large-icon">
                            <FontAwesomeIcon icon={faBed} /> Queen Bed and Two
                            Single Beds
                        </p>
                        <p className="room-type-large-icon">
                            <FontAwesomeIcon icon={faUser} /> 3 - 4 Adults
                        </p>
                        <hr />
                        <ul className="facilities-icon">
                            <li>
                                <FontAwesomeIcon icon={faCouch} />
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faUtensils} />
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faWifi} />
                            </li>
                           
                            <li>
                                <FontAwesomeIcon icon={faBath} />
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faTv} />
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faWheelchair} />
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faTshirt} />
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faParking} />
                            </li>
                        </ul>

                        <NormalLink
                            type="btn book-now"
                            rel="nofollow"
                            href={bookingLink}
                            text="Book Now"
                        />
                    </div>
                </div>
            </div>
        </section>
        <section className="white">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <h3>
                            Two Bedroom Apartments
                            <span>with mountain view</span>
                        </h3>
                        <p className="subtitle">
                            Maximum occupancy is 4 guests, approximate size
                            50sqm+.
                        </p>
                        <p>
                            With views of The Remarkables and Ben Lomond on both
                            sides, this lovely apartment comes with one Queen
                            bed and 2 Single beds - perfect for larger groups.
                            Enjoy all the space you need to feel at home with a
                            kitchenette to cater for cooking with the family,
                            and a dining and living area. Enjoy the vast
                            amenities that these apartments have to offer, like
                            your own private backyard for you to relax and enjoy
                            a quiet time with your loved ones.
                        </p>
                        <p className="room-type-large-icon">
                            <FontAwesomeIcon icon={faBed} /> Queen Bed and Two
                            Single Beds
                        </p>
                        <p className="room-type-large-icon">
                            <FontAwesomeIcon icon={faUser} /> 3 - 4 Adults
                        </p>
                        <hr />
                        <ul className="facilities-icon">
                            <li>
                                <FontAwesomeIcon icon={faCouch} />
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faUtensils} />
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faWifi} />
                            </li>
                         
                            <li>
                                <FontAwesomeIcon icon={faBath} />
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faTv} />
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faWheelchair} />
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faTshirt} />
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faParking} />
                            </li>
                        </ul>

                        <NormalLink
                            type="btn book-now"
                            rel="nofollow"
                            href={bookingLink}
                            text="Book Now"
                        />
                    </div>
                    <div className="col-12 col-lg-6 order">
                        <AccommodationSlider content='["https://d2057z2iq79qyw.cloudfront.net/amity/neighboue-2bed.jpg", "https://d2057z2iq79qyw.cloudfront.net/amity/mountain-view1.jpg", "https://d2057z2iq79qyw.cloudfront.net/amity/1bed-room-apt1.jpg"]' />
                    </div>
                </div>
            </div>
        </section>
        <section className="grey">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-12">
                        <p>
                            If you are keen on some light exercise then take a
                            short walk down the driveway to the nearby town
                            centre where you can enjoy the lakefront or a number
                            of galleries, shops and restaurants.
                        </p>

                        <p>
                            For the more adventurous, attractions close to home
                            are{" "}
                            <a
                                href="https://www.skyline.co.nz/en/queenstown/"
                                rel="nofollow"
                                target="_blank"
                            >
                                Skyline Gondola and Luge
                            </a>
                            , skiing on{" "}
                            <a
                                href="https://www.coronetpeak.co.nz/"
                                target="_blank"
                                rel="nofollow"
                            >
                                Coronet Peak
                            </a>
                            , or{" "}
                            <a
                                href="https://www.bungy.co.nz/"
                                target="_blank"
                                rel="nofollow"
                            >
                                Queenstown Bungy
                            </a>
                            , Swing and Zip, just to name a few. For the more
                            reserved, enjoy wine tasting and tours in one of the
                            many Queenstown wineries, some can even be done on a
                            helicopter! Or explore Lake Wanaka and enjoy the
                            natural beauty by sailing, kayaking or an evening
                            stroll.{" "}
                        </p>

                        <p>
                            With so much to do and so much to see, there's no
                            shortage of activities suitable for everyone. Click{" "}
                            <a href="/activities/">here</a> to see more
                            information on the attractions in Queenstown, and
                            make the most of this beautiful place we call home!
                        </p>

                        <p>
                            Still not convinced? Don't take our word for it.
                            Click <a href="/reviews/">here</a> to see honest
                            reviews from our guests, and{" "}
                            <a href="/gallery/">here</a> to view the gallery to
                            catch a glimpse of your perfect holiday here with
                            us.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    </div>
)

export default Accommodation;
