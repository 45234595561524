import React from "react";
import Meta from "../../global/Meta";
import { NormalLink } from "../../global/CustomLinks";
import FooterSeo from '../../global/FooterSeo';
import { bookingLink } from "../../global/constants";

const Covid = () => (
    <div>
        <Meta
            title="Self Isolation Covid-19 Queenstown Accommodation for stay"
            desc="Due to COVID-19 Amity Serviced Apartment provided to self isolate queenstown accommodation for 14 or 28 days as per The Ministry Of Health Guidelines. This is an effective precautionary measure to protect."
            keywords="covid 19 queenstown accommodation, self isolation queenstown accommodation, Coronavirus pandemic, long term stay in queenstown, short term stay in queenstown"
        />
        <h1>
            During Covid-19 Self Isolation Queenstown Accommodation at Amity
        </h1>
        <div class="header-inside">
            >
            <img
                src="/img/covid-19-self-isolation-accommodation-nz.jpg"
                alt="Covid 19 self isolation queenstown accommodation"
            />
            <div class="caption">
                <div class="caption-inner">
                    <h2>Amity Queenstown Welcomes you Back </h2>
                </div>
            </div>
        </div>
        <section class="grey">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-12">
                        <h3>Prevent the Spread of Covid-19!</h3>
                        <p>
                            It is certainly an unsettling time both in New
                            Zealand and around the world. <b>COVID-19</b> is an
                            evolving situation, and New Zealand has been on{" "}
                            <b>alert level 4</b> since the{" "}
                            <b>25th of March 2020</b>. You can visit{" "}
                            <a
                                rel="nofollow"
                                href="https://covid19.govt.nz/government-actions/covid-19-alert-system/#new-zealand-covid-19-alert-levels"
                                target="_blank"
                            >
                                this link{" "}
                            </a>
                            to see more information on the different alert
                            levels and what they mean for <b>New Zealand</b>.
                        </p>
                        <p>
                            There’s currently no vaccine to prevent coronavirus
                            disease. While{" "}
                            <a
                                href="https://covid19.govt.nz/government-actions/current-covid-19-alert-level/essential-businesses/"
                                target="_blank"
                                rel="nofollow"
                            >
                                essential services{" "}
                            </a>
                            remain open during the lockdown period,
                            non-essential workers should self-isolate at home in
                            order to best combat the spread of the Coronavirus,
                            and stop all physical interactions with others
                            outside of your household, while maintaining basic
                            hygiene measures, which include:
                            <ul>
                                <li>
                                    Washing your hands often with soap and water
                                </li>
                                <li>
                                    Avoiding touching your eyes, nose, and mouth
                                    with your hands
                                </li>
                                <li>Covering coughs and sneezes</li>
                                <li>
                                    Disinfecting high-touch surfaces, as the
                                    virus can remain on these surfaces
                                </li>
                            </ul>
                        </p>
                        <p>
                            Amity Queenstown Apartments take the health and
                            wellbeing of our guests and employees as our top
                            priority. Following the move into Lockdown Level 2
                            on the 14th of May, we have taken the opportunity to
                            review the status of all our apartments, and have
                            developed a comprehensive system where strict
                            hygiene practices are being adhered to.
                        </p>
                        <p>
                            Rest assured we pride ourselves on our cozy,
                            well-kept rooms, with free unlimited Wifi and Sky
                            TV, with a full kitchen on offer, providing a safe
                            and friendly space as if you were back home.
                        </p>
                        <p>
                            We invite you to follow our Prime Minister’s words:
                            "visit tourism sites and support local business."
                            Let’s help our community get back on its feet –
                            together.
                        </p>
                    </div>
                    <div class="col-12 col-lg-6 offset-lg-3 text-center">
                        <p>
                            Our apartments are now open to locals. We invite you
                            all to show the love and pride that we share for our
                            spectacular Queenstown. Book and apply the promo
                            code 'STAYSAFE20' to 20% off when you book with us
                            directly.
                        </p>
                        <NormalLink
                            type="btn book-now"
                            rel="nofollow"
                            href={bookingLink}
                            text="Book Now"
                        />
                        <p>&nbsp;</p>
                    </div>
                    <div class="col-12 col-lg-12">
                        <p>
                            Our absolute priority is the safety of our guests,
                            and in order to ensure that safety is present, we
                            will ensure that physical distancing takes place
                            within the apartment, and we have put extra measures
                            in place on our already strict hygiene practices.
                            New Zealand has an increasing number of Coronavirus
                            cases, so it is important that everyone, including
                            overseas visitors, stay in one place for the
                            duration of their stay. Please visit{" "}
                            <a
                                href="https://www.health.govt.nz/our-work/diseases-and-conditions/covid-19-novel-coronavirus/covid-19-current-situation/covid-19-current-cases"
                                target="_blank"
                                rel="nofollow"
                            >
                                the current official COVID-19 cases
                            </a>{" "}
                            to see more information, updated regularly.
                        </p>
                        <p>
                            While <b>self-isolation</b> is in place for all New
                            Zealanders, you can still leave the house to visit
                            essential services like supermarkets, banks and
                            pharmacies, go to work if you’re working in the
                            essential services sector, or go out for a walk to
                            enjoy nature. Doing this is much easier when you’re
                            staying with us at Amity Apartments, as you can
                            enjoy breathtaking views of the surrounding
                            mountains and lakes, from dusk till dawn. Being
                            situated so close to these facilities can keep your
                            adventures close to home, all while maintaining good
                            social distancing with other locals.
                        </p>
                        <p>
                            If the endless wonders of Queenstown has left you
                            feeling inspired to get outdoors, please take time
                            to read and follow the{" "}
                            <a
                                href="https://medium.com/@nz_msc/can-you-go-walking-tramping-trail-running-or-hunting-during-covid-19-alert-level-4-9448630eae78"
                                target="_blank"
                                rel="nofollow"
                            >
                                Level 2 Alert Guidelines.
                            </a>
                        </p>
                        <p>
                            In the meantime, please stay safe and remember to
                            only travel if it is absolutely essential.
                        </p>
                        <p>
                            Sending well wishes to you all,
                            <br />
                            <b>Amity Serviced Apartments, NZ</b>
                        </p>
                    </div>
                </div>
            </div>
        </section>
    </div>
)

export default Covid;
