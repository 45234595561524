import React from "react";
import {
  faCar,
  faWifi,
  faTshirt,
  faUserClock,
  faHandHoldingUsd,
  faGift,
  faSuitcase,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const WhyBookWithUs = () => (
  <div class="whybookwithus">
    <h3>Why book with us?</h3>
    <ul>
      <li>
        <div>
          <FontAwesomeIcon icon={faCar} />
        </div>
        FREE off-street Parking
      </li>
      <li>
        <div>
          <FontAwesomeIcon icon={faWifi} />
        </div>
        FREE unlimited Wifi
      </li>
    
      {/*
      <li>
        <div>
          <FontAwesomeIcon icon={faGift} />
        </div>
         FREE Continental Breakfast
      </li>
      */}

      <li>
        <div>
          <FontAwesomeIcon icon={faUserClock} />
        </div>
        Late CHECKOUT
      </li>
      <li>
        <div>
          <FontAwesomeIcon icon={faSuitcase} />
        </div>
        BAGGAGE storage
      </li>
      <li>
        <div>
          <FontAwesomeIcon icon={faTshirt} />
        </div>
        LAUNDRY service
      </li>
      <li>
        <div>
          <FontAwesomeIcon icon={faHandHoldingUsd} />
        </div>
        BEST Price Guarantee
      </li>
    </ul>
  </div>
);

export default WhyBookWithUs;
