import React from 'react';
import styled from 'styled-components';

const FooterSeoWrapper = styled.div`
    position: absolute;
    top: -1000px;
    z-index: -1;
`;


const FooterSeo = ({content}) => (
    <FooterSeoWrapper>
        {content}
    </FooterSeoWrapper>
);

export default FooterSeo;