import React from "react";
import Meta from "../../global/Meta";

import GalleryJson from "./GalleryJson";
import "../Activities/Activities.scss";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

const Gallery = () => (
  <div className="activities">
    <Meta
      title="Gallery - Amity Serviced Apartments Accommodation in Queenstown"
      desc="Visit our gallery to see amity serviced apartments in Queenstown consists of spacious lounge, dining and living areas, with views of mountains, one bedroom, two bedrooms, comfortable and centrally located Queenstown accommodation available at Amity queenstown accommodation"
      keywords="Modern Self-Contained Serviced Apartments in Queenstown | New Zealand"
    />

    <div className="header-inside">
      <img
        src="https://d2057z2iq79qyw.cloudfront.net/amity/frontlook.jpg"
        alt="Amity Gallery queenstown accommodation"
      />
      
      <div className="caption">
        <div className="caption-inner">
          <h2>Gallery</h2>
          <p>Scroll through the images and catch a glimpse before you stay</p>
        </div>
      </div>
    </div>
    <section className="white">
      <div class="container-fluid">
        <div className="row">
          {GalleryJson.map((item, index) => (
            <div className="col-12 col-lg-4 col-sm-6">
              <div className="activityBlock">
                <Zoom zoomMargin={40}>
                  <img
                    alt={item.title}
                    src={item.previewImage}
                    className="img-fluid"
                  />
                </Zoom>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  </div>
);

export default Gallery;
