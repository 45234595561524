import React from "react";
import Meta from "../../global/Meta";

import "./Contact.scss";

const Contact = () => (
  <div>
    <Meta
      title="Queenstown Accommodation | Secure Booking | No Fee"
      desc="To enquire contact us on 0800 556 000. Amity Queenstown Accommodation"
      keywords="Accommodation in Queenstown, NZ, motel, apartment, accommodation deals, one bedroom aprtment, two bedroom apartment"
    />
    <h1>
      Enquire online or call us today 0800556000 to Book Low Price Queenstown
      Accommodation
    </h1>
    <div className="header-inside">
      <img src="https://d2057z2iq79qyw.cloudfront.net/amity/frontlook.jpg" alt="Contact us page" />
      <div className="caption">
        <div className="caption-inner">
          <h2>Contact us</h2>
          <p>For assistance with your bookings! </p>
        </div>
      </div>
    </div>
    <section class="homepage-about-us">
      <div class="container">
        <div class="row">
          <div class="col-md-5 col-12">
            <p>
              Situated on a quiet street just a short walk from the town centre,
              galleries, cafes and shops are just around the corner.
            </p>
            <p>
              Amity is situated just 8km from Queenstown airport, so taxi
              shuttle services will be sure to drop you to your door.
            </p>
            <p>
              If you are coming by car, look out for Melbourne St as you
              approach Queenstown. Make a right when you see Hurleys Motel and
              The Millennium Hotel, which is situated right across the street
              from us.
            </p>
          </div>
        </div>
      </div>
    </section>

   
  </div>
);

export default Contact;
