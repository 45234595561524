import React, { useState } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEnvelope, faPhone, faMap } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CustomButton } from "../CustomLinks";
import { NavLink } from "react-router-dom";
import logo from "../../../img/amitylogo.png";
import "./Footer.scss";

library.add(fab);

const initialFormData = Object.freeze({
  name: "",
  email: "",
  phone: "",
  message: "",
});

const Footer = () => {
  const [formData, updateFormData] = useState(initialFormData);
  const [resultData, setData] = useState({});

  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value.trim(),
    });
  };
 
  const formHandler = (e) => {
    // e.preventDefault();
    //async () => {
    
    fetch("/sendemail1.php", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        message: formData.message,
      }),
    })
      .then((res) => res.json())
      .then((res) => setData(res));
    //console.log(res.json());
    //res.json().then(res => setData(res));
    //};
    return false;
  };

  //console.log(resultData);
  return (
      <section className="footer">
          <div className="container">
              <div className="row">
              <div className="col-12 col-lg-12 text-center">
                    <NavLink exact className="logo" to="/">
                        <img
                        className="logo"
                        src={logo}
                        alt="Serviced apartments accommodation in Queenstown"
                        />
                    </NavLink>
                </div>
                  {/*
                  
  
                  <div className="col-12 col-lg-8">
                      <h4>Get in touch</h4>
                      <form id="consultationForm">
                          {resultData && resultData.err && (
                              <div className="alert alert-danger">
                                  <div className="row">
                                      <div className="col-12">
                                          {resultData.err}
                                      </div>
                                  </div>
                              </div>
                          )}
                          {resultData && resultData.success && (
                              <div className="alert alert-success">
                                  <div className="row">
                                      <div className="col-12">
                                          Thanks for submitting your request
                                      </div>
                                  </div>
                              </div>
                          )}

                          <div className="row">
                              <div className="col-12 col-md-6">
                                  <input
                                      className="form-control"
                                      type="text"
                                      name="name"
                                      placeholder="Name"
                                      onChange={handleChange}
                                  />
                                  <input
                                      className="form-control"
                                      type="text"
                                      name="phone"
                                      placeholder="Phone"
                                      onChange={handleChange}
                                  />
                                  <input
                                      className="form-control"
                                      type="text"
                                      name="email"
                                      placeholder="Email"
                                      onChange={handleChange}
                                  />
                              </div>
                              <div className="col-12 col-md-6">
                                  <textarea
                                      className="form-control"
                                      name="message"
                                      placeholder="How can we help?"
                                      rows="5"
                                      onChange={handleChange}
                                  />
                              </div>
                              <div className="col-12 text-right">
                                  <CustomButton 
                                    id="submitHandler"
                                      clickHandler={formHandler}
                                      type="btn btn-submit"
                                      text="Submit"
                                  />
                              </div>
                          </div>
                      </form>
                  </div>
                  */}
                  <div className="col-12 col-lg-12 follow-us whybookwithus">
                     {/* <h4>Follow us</h4> */}
                      <hr />
                      <ul>
                          <li>
                              <div>
                                  <FontAwesomeIcon icon={faEnvelope} />
                              </div>{" "}
                              <a href="mailto:info@amityqueenstown.co.nz">
                                  info@amityqueenstown.co.nz
                              </a>
                          </li>
                          <li>
                              <div>
                                  <FontAwesomeIcon icon={faPhone} />
                              </div>{" "}
                              <a href="tel://0800556000">0800 556 000</a>
                          </li>
                          <li>
                              <div>
                                  <FontAwesomeIcon icon={faPhone} />
                              </div>{" "}
                              <a href="tel://006434427288">+64 3 442 7288 </a>
                          </li>
                          <li>
                              <div>
                                  <FontAwesomeIcon
                                      icon={["fab", "facebook-f"]}
                                  />
                              </div>{" "}
                              <a
                                  rel="nofollow"
                                  href="https://www.facebook.com/amityservicedapartmentsqueenstown/"
                              >
                                  amityservicedapartmentsqueenstown
                              </a>
                          </li>
                          <li>
                              <div>
                                  <FontAwesomeIcon icon={faMap} />
                              </div>{" "}
                              <a
                                  rel="nofollow"
                                  href="https://goo.gl/maps/MHWMvFnC6KwvwuP17"
                              >
                                  7 Melbourne St, Queenstown 9348,
                              </a>
                          </li>
                          <li>
                              <div>
                                  <FontAwesomeIcon icon={faMap} />
                              </div>{" "}
                              <a
                                  rel="nofollow"
                                  href="https://goo.gl/maps/MHWMvFnC6KwvwuP17"
                              >
                                  PO Box  371, Queenstown 9300,
                              </a>
                          </li>
                      </ul>
                  </div>
                  
              </div>
              <div className="row">
                  <div className="col-12">
                      <hr />
                  </div>
              </div>
              <div className="row copyright">
                  <div className="col-12">
                      &copy; 2019 Amity Serviced Apartments. All Rights
                      Reserved. Developed by{" "}
                      <a href="http://www.skyhi.co.nz" rel="nofollow">
                          SkyHi Agency
                      </a>
                  </div>
              </div>
          </div>
      </section>
  )
};

export default Footer;
