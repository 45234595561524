import React from "react";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";

const CustomLink = props => (
  <NavLink className={props.type} to={props.href}>
    {props.text} <FontAwesomeIcon icon={faArrowRight} />
  </NavLink>
);

const CustomLink1 = props => (
  <div className={props.type}>
    {props.text} <FontAwesomeIcon icon={faArrowRight} />
  </div>
);

const CustomButton = props => (
  <button
    type="button"
    id={props.id}
    className={props.type}
    onClick={() => props.clickHandler()}
  >
    {props.text} <FontAwesomeIcon icon={faArrowRight} />
  </button>
);

const NormalLink = props => (
  <a className={props.type} href={props.href}>
    {props.text} <FontAwesomeIcon icon={faArrowRight} />
  </a>
);
export { CustomLink, CustomLink1, CustomButton, NormalLink };
