import React from "react";
import { CustomLink1 } from "../../global/CustomLinks";
import "./Deals.scss";
import { bookingLink } from "../../global/constants";

const Deals = () => (
    <div id="deals">
        <h3>Our Exclusive Offer</h3>
        <div className="row">
            <div className="col-12 col-md-12">
                <a className="card-block" href={bookingLink}>
                    <img src="https://d2057z2iq79qyw.cloudfront.net/amity/3nightspecial.jpg" alt="booking deals" />
                    <div className="overlay"></div>
                    <div className="content-1">
                        <CustomLink1
                            type="btn book-now"
                            href={bookingLink}
                            rel="nofollow"
                            text="Book Now"
                        />
                    </div>
                </a>
            </div>
        </div>
    </div>
)

export default Deals;
