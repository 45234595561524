import React from "react";
import { getImageUrl } from "../../provider/api";

const Banner = ({ item }) => {
  return (
    <div className="header-inside">
      <img src={getImageUrl(item.imgName)} alt="Specials page banner" />
      {item.heading && (
        <div className="caption">
          <div className="caption-inner">
            <h2>{item.heading}</h2>
            <p>{item.subHeading}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Banner;
