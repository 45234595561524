import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { withRouter } from "react-router";
import MessengerCustomerChat from "react-messenger-customer-chat";
import "bootstrap/scss/bootstrap.scss";
import "./scss/custom.scss";
import Header from "./components/global/Header/Header";
import Footer from "./components/global/Footer/Footer";

import Home from "./components/pages/Home/Home";
import About from "./components/pages/About/About";
import Accommodation from "./components/pages/Accommodation/Accommodation";
import AccommodationDetail from "./components/pages/Accommodation/AccommodationDetail";
import Specials from "./components/pages/Specials/Specials";
import SpecialDetail from "./components/pages/Specials/SpecialDetail";
import Activities from "./components/pages/Activities/Activities";
import Gallery from "./components/pages/Gallery/Gallery";
//import ActivitiesDetail from "./components/pages/Activities/ActivitiesDetail";
import Contact from "./components/pages/Contact/Contact";
import Reviews from "./components/pages/Reviews/Reviews";
import Privacy from "./components/pages/Privacy/Privacy";

import Covid from "./components/pages/Covid/Covid";
import Links from "./components/pages/Links/Links";
import VacationApartment from "./components/pages/Accommodation/VacationApartment";
import Blog from "./components/pages/Blog/Blog";
import BlogDetail from "./components/pages/Blog/BlogDetail";
import { getMenu, getAllPageData } from "./components/provider/api";

const App = () => {
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  const [pageData, setPageData] = useState([]);
  const [menu, setMenu] = useState([]);
  useEffect(() => {
    getMenu().then((res) => setMenu(res.data));
    getAllPageData("/api.json").then((res) => setPageData(res.data));
  }, []);
  console.log(menu, pageData);
  return (
    <div>
      {window.location.pathname === "/header" ||
      window.location.pathname === "/header/" ? (
        <div>
          <Header />
          { /* <MessengerCustomerChat
            pageId="185002730612"
            appId="235449540813132"
            version="6.0"
            htmlRef={window.location.pathname}
            loggedInGreeting="How can we help you?"
      /> */ }
        </div>
      ) : window.location.pathname === "/footer" ||
        window.location.pathname === "/footer/" ? (
        <Footer />
      ) : (
        <div>
          {/*getWindowDimensions().width >= 768 && (
            <MessengerCustomerChat
              pageId="185002730612"
              appId="235449540813132"
              version="6.0"
              htmlRef={window.location.pathname}
              loggedInGreeting="How can we help you?"
            />
          )*/}
          <Header />
          <hr />
          <Route exact path="/" component={Home} />
          <Route exact path="/accommodation/" component={Accommodation} />
          <Route path="/accommodation/:id/" component={AccommodationDetail} />
          <Route
            exact
            path="/eagles-nest-apartment/"
            component={Accommodation}
          />
          <Route exact path="/specials/" component={Specials} />
          <Route path="/specials/:id/" component={SpecialDetail} />
          {pageData && (
            <Route
              exact
              path="/blog/"
              component={() => <Blog pageData={pageData} />}
            />
          )}
          {pageData &&
            menu.map(
              (item, index) =>
                item.children &&
                item.children.length > 0 &&
                item.children.map((item1, index1) => (
                  <Route
                    exact
                    key={index1}
                    path={"/" + item.slugName + "/" + item1.slugName + "/"}
                    component={() => (
                      <BlogDetail
                        menu={menu}
                        item={item1}
                        apiurl={item1.slugName}
                        pageData={pageData}
                      />
                    )}
                  />
                ))
            )}

          <Route path="/about/" component={About} />
          <Route path="/activities/" component={Activities} />
          <Route path="/contact/" component={Contact} />
          <Route exact path="/reviews/" component={Reviews} />
          <Route exact path="/gallery/" component={Gallery} />
          <Route exact path="/privacypolicy/" component={Privacy} />
          <Route
            exact
            path="/covid-19-self-isolation-accommodation/"
            component={Covid}
          />
          <Route
            exact
            path="/self-contained-serviced-apartments/"
            component={VacationApartment}
          />
          <Route exact path="/links/" component={Links} />

          <Footer />
        </div>
      )}
    </div>
  );
};

const AppWrapper = withRouter(App);

const WebsiteApp = (props) => {
  return (
    <Router>
      <AppWrapper {...props} />
    </Router>
  );
};

export default WebsiteApp;
