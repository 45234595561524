import React from "react";
import { getImageUrl } from "../../provider/api";
import { CustomButton } from "../../global/CustomLinks";

const MultipageSection = ({ item, index }) => {
  const clickLink = (url) =>{
    window.scrollTo(0, 0)
    window.location.href = url;
  }
  return (
    <section className={index % 2 ? "grey" : "white"}>
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-4 d-block d-lg-none">
            <img
              className="img-fluid"
              src={getImageUrl(item.multiImage)}
              alt={item.multiName}
            />
          </div>
          <div className="col-12 col-lg-8">
            <h3>{item.multiName}</h3>
            <p dangerouslySetInnerHTML={{ __html: item.multiDesc }}></p>
            <CustomButton
              type="btn book-now"
              clickHandler={() => clickLink(item.multiPageUrl)}
              
              rel="nofollow"
              text="Read more"
            />
          </div>
          <div className="col-12 col-lg-4 d-none d-lg-block">
            <img
              className="img-fluid"
              src={getImageUrl(item.multiImage)}
              alt={item.multiName}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MultipageSection;
