import React from "react";
import { NormalLink } from "../../global/CustomLinks";
import { bookingLink } from "../../global/constants";

const ContentSection = ({ item }) => (
  <section className="white">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <a href="/blog/"> Back</a>
        </div>
      </div>
    </div>
    <div className="container">
      <div classNam="row">
        <div className="col-12">
          <h2>{item.heading}</h2>
          <p
            dangerouslySetInnerHTML={{
              __html: item.content,
            }}
          ></p>
          <NormalLink
            type="btn book-now"
            href={bookingLink}
            rel="nofollow"
            text="Book Now"
          />
        </div>
      </div>
    </div>
  </section>
);

export default ContentSection;
