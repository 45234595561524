import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
//import { images_base_url } from '../../global/constants'


class AccommodationSlider extends React.Component {
  render() {
    var settings = {
      dots: false,
      infinite: true,
      fade: true,
      autoplay: true,
      speed: 3000,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    this.content = JSON.parse(this.props.content);
    return (
      <Slider {...settings}>
        {this.content.map((item, key) =>
            <div key={key}>
                <img src={item} alt={item.replace(".jpg", "").replace("/img/", "").replace(/-/g, " ")} />
            </div>
        )}
      </Slider>
    );
  }
}

export default AccommodationSlider