import React from "react";
import { NavLink } from "react-router-dom";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NormalLink } from "../../global/CustomLinks";
import "./Nav.scss";
import { bookingLink } from "../../global/constants";

const isActive = (path, match, location) =>
  !!(match || path === location.pathname);

const Nav = (props) => {
  const burgerToggle = () => {
    let linksEl = document.querySelector(".narrowLinks");
    if (linksEl.style.display === "block") {
      linksEl.style.display = "none";
    } else {
      linksEl.style.display = "block";
    }
  };
  return (
    <nav className="navbar navbar-expand-lg">
      {props.wide && (
        <div className="navWide navbar-collapse">
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink
                activeClassName="active-link"
                className="nav-link"
                to="/"
                exact
                isActive={isActive.bind(this, "/")}
              >
                Home <span className="sr-only">(current)</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                activeClassName="active-link"
                className="nav-link"
                to="/accommodation/"
                isActive={isActive.bind(this, "/accommodation")}
              >
                Accommodation
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                activeClassName="active-link"
                className="nav-link"
                to="/specials/"
                isActive={isActive.bind(this, "/specials")}
              >
                Specials
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                activeClassName="active-link"
                className="nav-link"
                to="/activities/"
                isActive={isActive.bind(this, "/activities")}
              >
                Activities
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                activeClassName="active-link"
                className="nav-link"
                to="/reviews/"
                isActive={isActive.bind(this, "/reviews")}
              >
                Reviews
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                activeClassName="active-link"
                className="nav-link"
                to="/gallery/"
                isActive={isActive.bind(this, "/gallery")}
              >
                Gallery
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                activeClassName="active-link"
                className="nav-link"
                to="/about/"
                isActive={isActive.bind(this, "/about")}
              >
                About
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                activeClassName="active-link"
                className="nav-link"
                to="/contact/"
                isActive={isActive.bind(this, "/contact")}
              >
                Contact
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                activeClassName="active-link"
                className="nav-link"
                to="/blog/"
                isActive={isActive.bind(this, "/blog")}
              >
                Blog
              </NavLink>
            </li>
          </ul>
        </div>
      )}
      {!props.wide && (
        <div className="navNarrow">
          <div className="burgerMenu" onClick={() => burgerToggle()}>
            Menu <FontAwesomeIcon icon={faBars} />
          </div>

          <div className="btn-color">
            <NormalLink
              type="btn book-now"
              rel="nofollow"
              href={bookingLink}
              text="Book Now"
            />
          </div>
          <div className="narrowLinks">
            <div className="cross" onClick={() => burgerToggle()}>
              <FontAwesomeIcon icon={faTimes} />
            </div>
            <ul className="navbar-nav">
              <li className="nav-item">
                <NavLink
                  activeClassName="active-link"
                  className="nav-link"
                  to="/"
                  exact
                  isActive={isActive.bind(this, "/")}
                  onClick={() => burgerToggle()}
                >
                  Home <span className="sr-only">(current)</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  activeClassName="active-link"
                  className="nav-link"
                  to="/accommodation/"
                  isActive={isActive.bind(this, "/accommodation")}
                  onClick={() => burgerToggle()}
                >
                  Accommodation
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  activeClassName="active-link"
                  className="nav-link"
                  to="/specials/"
                  isActive={isActive.bind(this, "/specials")}
                  onClick={() => burgerToggle()}
                >
                  Specials
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  activeClassName="active-link"
                  className="nav-link"
                  to="/activities/"
                  isActive={isActive.bind(this, "/activities")}
                  onClick={() => burgerToggle()}
                >
                  Activities
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  activeClassName="active-link"
                  className="nav-link"
                  to="/reviews/"
                  isActive={isActive.bind(this, "/reviews")}
                  onClick={() => burgerToggle()}
                >
                  Reviews
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  activeClassName="active-link"
                  className="nav-link"
                  to="/gallery/"
                  isActive={isActive.bind(this, "/gallery")}
                  onClick={() => burgerToggle()}
                >
                  Gallery
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  activeClassName="active-link"
                  className="nav-link"
                  to="/about/"
                  isActive={isActive.bind(this, "/about")}
                  onClick={() => burgerToggle()}
                >
                  About
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  activeClassName="active-link"
                  className="nav-link"
                  to="/contact/"
                  isActive={isActive.bind(this, "/contact")}
                  onClick={() => burgerToggle()}
                >
                  Contact
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  activeClassName="active-link"
                  className="nav-link"
                  to="/blog/"
                  isActive={isActive.bind(this, "/blog")}
                  onClick={() => burgerToggle()}
                >
                  Blog
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Nav;
