import React from "react";
import Meta from "../../global/Meta";
import activitiesBanner from "../../../img/queenstown-activities.jpg";
import ActivitiesJson from "./ActivitiesJson";
import "./Activities.scss";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

const Activities = () => (
  <div className="activities">
    <Meta
      title="Activities in Queenstown | Amity Serviced Apartments"
      desc="Queenstown provides the ultimate thrills with skydiving, bungy jumping, horse riding, jet boating, luging, zip lining and paragliding. Amity Apartments can help with your booking "
      keywords="adventures, activities, things to do, queenstown, NZ attractions, remarkables"
    />
    <h1>Must Visit Attractions &amp; Activities in Queenstown</h1>
    <div className="header-inside">
      <img src={activitiesBanner} alt="Activities Page banner" />
      <div className="caption">
        <div className="caption-inner">
          <h2>Activities</h2>
          <p>Queenstown New Zealand, the adventure capital of the world</p>
        </div>
      </div>
    </div>
    <section className="white">
      <div class="container-fluid">
        <div className="row">
          {ActivitiesJson.map((item, index) => (
            <div className="col-12 col-lg-3 col-sm-6">
              <div className="activityBlock">
                <Zoom zoomMargin={40}>
                  <img
                    className="img-fluid"
                    src={item.previewImage}
                    alt={item.title}
                  />
                </Zoom>
                <div className="content">
                  <h4>{item.title}</h4>
                  <p dangerouslySetInnerHTML={{ __html: item.intro }}></p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  </div>
);

export default Activities;
