const SpecialJson = [
  {
    title: "Our Best Seller Deals - 10%off",
    slugName: "night-special",
    intro:
      "<p>Plan smart and save. Enjoy many additional benefits while you book this package with us.</p>",
    previewImage: "/img/book-accommodation-queenstown.jpg",
    bannerImage: "/img/book-accommodation-queenstown.jpg",
    fullDescription:
      "<p>Early check - in and late check- out ( subject to availability )</p><p>Just goes without saying -  High speed WiFi, and Sky channels 50+ including sky sports, On site car park is free but subject to availability.</p><p>Allow us to welcome you to our friendly and warm hospitality.</p><p>Enjoy 20% off original booking prices if you book to stay with us during the three Nights period.</p><p><b></b></p>",
    metaTitle: "Night Special Deals in Queenstown NZ",
    metaDesc:
      "Celebrate the three Nights with us and get 20% off on queenstown accommodation. For more information or booking please contact 0800556000",
    metaKeywords:
      "queenstown night, activities to do, night in queenstown, New Zealand, accommodation deal, night special, ",
  },
  
];

export default SpecialJson;
