import React from "react";
import Meta from "../../global/Meta";

import { CustomLink } from "../../global/CustomLinks";
import SpecialJson from "./SpecialJson";

const Specials = () => (
  <div className="specials">
    <Meta
      title="Exclusive Specials | Amity Queenstown Accommodation"
      desc="Discover exclusive specials and deals at Amity Queenstown Accommodation. Book now for amazing savings on your Queenstown getaway!"
      keywords="Queesntown events, upcoming offers, Serviced Apartments Queenstown, Accommodation in Queenstown"
    />
    <h1>Queenstown Accommodation with fantastic views &amp; deals</h1>
    <div className="header-inside">
      <img src="https://d2057z2iq79qyw.cloudfront.net/amity/queenstown-hill.jpg" alt="Specials page banner" />
      <div className="caption">
        <div className="caption-inner">
          <h2>Specials</h2>
          <p>NOT TO BE MISSED DEALS, ONE IS YOURS!</p>
        </div>
      </div>
    </div>
    {SpecialJson.map((item, index) => (
      <section className={index % 2 ? "grey" : "white"}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-4 d-block d-lg-none">
              <img
                className="img-fluid"
                src={item.previewImage}
                alt={item.title}
              />
            </div>
            <div className="col-12 col-lg-8">
              <h3>{item.title}</h3>
              <p dangerouslySetInnerHTML={{ __html: item.intro }}></p>
              <CustomLink
                type="btn book-now"
                href={"/specials/" + item.slugName + "/"}
                rel="nofollow"
                text="Read more"
              />
            </div>
            <div className="col-12 col-lg-4 d-none d-lg-block">
              <img
                className="img-fluid"
                src={item.previewImage}
                alt={item.title}
              />
            </div>
          </div>
        </div>
      </section>
    ))}
  </div>
);

export default Specials;
